.swap-component  {
  box-sizing: border-box;
  background-color: #bebebe;
  max-width: 100vw;
  height: 100%;
  flex-grow: 1;
  overflow-y: auto;
}

.swap-component>div {
  border: #d1d1d0 outset 2px;
  margin: 2px;
}

.tss{
  display: flex;
  flex-direction: column;
 flex-grow: 1;

    width: 100%;
      box-sizing: border-box;
     
}


.edit-mode {
  max-width: 600px;
  padding: 10px;
}

.edit-mode .swap-form {
  border: none;
  box-shadow: none;
}

.field-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}
.field-group>*{
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.field-group label {
  flex: 1 0 0;
  /* Label width */
  max-width: 100%;
  margin-right: 10px;

}

.field-group button,
.field-group input,
.field-group select,
.field-group span {
  flex: 1;
  /* Grow to fill space */
  min-width: 20px;
  /* Minimum width of inputs and button */
  margin-right: 5px;
  /* Spacing between elements */
   overflow: hidden;
   text-overflow: ellipsis;

}

@media (max-width: 300px) {

  /* Adjust breakpoint as needed */
  .field-group {
    flex-direction: column;
    align-items: stretch;
  }

  .field-group label {
    flex: 0 0 auto;
    /* Allow label to fit content */
    margin-right: 0;
    /* Remove right margin on small screens */
  }

  .field-group button,
  .field-group input,
  .field-group select,
  .field-group span {
    width: 100%;
    /* Full width on small screens */
    margin-right: 0;
    /* Remove right margin on small screens */
    margin-bottom: 5px;
    /* Add some bottom margin for spacing */
  }
}

.swap-component input[type="text"], .send-component input[type="text"] {
  margin-left: 10px;
  text-align: right;
}

.select-button{
  max-width: 50px;
  min-width: none!important;
  display: flex ;
  align-items: center;
}

.select-button .token{
  display: flex;
  flex-grow: 1;
  align-self: center;
  margin-top: 4px;
}

.swap-toolbar{
  background-color: #c0c0c0;
display: flex;
}

.swap-toolbar-icon {
  margin:auto;
  font-size: 2em ;
}

.swap-toolbar-button
{
  margin: 2px 5px 2px 2px;  
  border: #d1d1d0 outset 2px;
  background-color: #c0c0c0;

}

@media screen and (max-width: 768px){
  .swap-toolbar-button{
    font-size: 0.6em;
  }
  
}


.swap-toolbar-button:hover
{
  background-color: #d1d1d0;
}
.swap-toolbar-button:active
{
  background-color: #bebebe;
  border: #bebebe inset 2px;
}

.field-group label {
  font-weight: 600;
}
.optimal-route{
  
  font-size: 0.8em;
  font-weight: 600;
  color: #0000ff;
  box-sizing: border-box;
}

.status-text{
  font-size: 0.8em;
  font-weight: 600;
  background-color: #c0c0c0;
  padding: 3px;
  border: #d1d1d0 outset 2px;
}

.swap-progress-container{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
  flex-direction: column;
}

.swap-explorer{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px;
  flex-direction: column;
  font-size: 0.8em;
  font-weight: 600;

}

.field-group .address{
  width: calc(100% - 10px);
  font-size: 0.6em;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}

.swap-component .tooltip {
  width:fit-content;
  max-width: 100%;
  padding: 5px;
  border-bottom: 1px dotted black;
  background-color: #eaebf4;
  color: black;
  font-weight: 400;
  cursor: help;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  float: right;
  text-align: left;
}

.optimal-route{
  width: 100%;
  /* text-align: right; */
  
}

.token-select{
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-direction: column;
  align-self: baseline;
  flex-grow: 1;
}

.radio-select{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-direction: column;
  flex-grow: 1;
  align-self: center;
      justify-content: space-around;
        height: 70px;
}

.token-select-group{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-direction: row;
  flex-grow: 1;
}

.amt-box{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-direction: row;
  flex-grow: 1;
}
.amt-box>div{
  flex-grow: 1;
  width: 50%;
  /* display: flex; */
}
.amt-box>div:first-of-type{
  display: flex;
}
.amt-box>div:has(input){
  display: flex;
  align-items: center;
}

.amt-box input{
  flex-grow: 1;
  width: 100%;
  text-align: right;
  position: relative;
  /* padding-right: 20px; */
}

input[type="number"]::-webkit-outer-spin-button ,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button !important;
    opacity: 1 !important;
  /* background: #999 url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAwCAIAAAD/zu84AAAALHRFWHRDcmVhdGlvbiBUaW1lAGZyZSAyOCBqdW4gMjAyNCAxMDoyNjozNCArMDEwMPdP+bUAAAAHdElNRQfoBhwILAp0u/3AAAAACXBIWXMAAAsSAAALEgHS3X78AAAABGdBTUEAALGPC/xhBQAABOBJREFUeNq1l8dLZFkUxgtcCf4pLnSriAou3YgLwY3iRhTDqKBi1jFnMeec2tC2AXMYQ6lt2oiMgghiGBExZ5zuH+++trqqy7ZuVftBPR73nXO+e889qTSfPhgafgMDA7Ozs8vLy18VrKysfLUAa2tr6+vri4uLQ0NDKgHWDw8Pb29v7+7ubi3Azc0Nz8fHx6enp9PT04WFBZWAvfPhm4L/LYYwcnZ2piPAJ/f393x4eHg4OTnhNMfHx//JA10U9/b2Njc3tVrt4OCgjoATQIsQHoR5aWlJ1vWrq6u4HsX+/v6ysrLCwsKGhgYdAd5/eXk5ODiYn58fHx+fnp7+RxJzc3PsDMWmpqa4uLiYmJjKykq9E0CAc7j9qakprn1eEviE7aPY3t6elpaWnJxcXV1t6KKjoyMunO1ofwC+FQXEXHR0tI2NjY+PT0dHB7bwCU/tT0AXpq6urpycnIyMjNra2jcJFhUgzTunHhkZaWxsDAgIsLa29vT0LC0t5QInJydnZmbwjNgHZBIEPFHDV9xYfX19enp6YGCgi4uLlZWVvb29r69vYmJiRUVFT08PFwbHa1CYSoB1nmQ41rkuV1dXjT5sbW2DgoKIFjgmJia4XlEITCVAh70TBvHx8R4eHhpjcHJyioiIKCoqwsLo6CjXyzlMJeju7q6pqQkLC3N2dta8DTs7Oz8/v4KCAq6de+IcphK0tLSQKd7e3pr34ObmlpSUhCc5MZdhKkFvby9hk5qa+peCyMjI6F8QHh4eEhISGxvLTWBkeHgY6xzCOAGZDAGVRBAQP2NjY1zyZwVfjIF19sELpglZtLBOdOBeQwLqDwWWYkeNJX1EWpoNWHNzc/UIMEodhYByyGlE7ixJQtQ7FAlcQwIibH9/n1JKmSVxyFJOPSoJXEpwo0ixy8zMhKCurk4l4FC4G2+2tbVVVVWVl5dXygNFqhvpnZeXR6WDg9BSCSjc2dnZCQkJFIC/LQbWU1JSoCEOVQKosrKyIOADzLxnWgDUiaKSkpLm5maVAM/gr2oFtRaDEsATr9AYVAIimkSf/EOYUsCd9/X1qQSsbm9vk8YE0rHFIJkYKWj9dAuVgHZxcXHx7Y/i6uqK5DAcWwA1Q8xPsrhTwMv19TUn2N3dxVF6tQjr5+fnOzs7pNvW1ta/ksDJ6KKIP8RE1NnZaWSqEJ1SjCFSoNJhGkVikmyghhtOFWIuQo6MN6PGiRLJxdJLSClGI3L7zX5gYrkGyCCJPNt/7cnUBeaEdxoOmcJJQ0NDaThRUVEG3YYV1vmKDJLIi4Iq0TLptF5eXu+2TGSQRF6OgK7EFbFBRoffWOcrMkgiL0fAPeNlai8t193d3ah11vmKDJLIyxGIwYtBASHc7ejoaGCdFdb5ioyQlyMQoyM1it0hx3jh7+/v4OCAaZ68s8I6X5ERY68cwc/DLyFII0SakRQCnryzIvIRGSEs7aLX8Z1WjiabDQ4OhoAn76LF8/VV8ncEHz5VMBc9Pz+Lucjy/2hG5iLccnl5yf9bhhfLix1F1NBF9Es2vrGxwZ8ZUoZeKiqtFHA9zkGRzCDG6P66uai1tZXKV1xcnJ+fD3m2ghyzgCLWqdW86OYiMbbwX4ORhqOJ6SPDXKCLdb2xhRMwazCdibGlToF5M4vQpb5inX8lKsGH4jvtTlxiE0lhiQAAAABJRU5ErkJggg==') !important; */
  background-size:cover !important;
  margin: 0;
  border: 0;
  height: 100%;
  aspect-ratio: 13/19;
  cursor: pointer;
  /* position: absolute; */
  top:0;
  right: 0;
 transform: translateX(4px);


}

.swap-component button{
  margin: 2px 5px 2px 2px;  
  border: #d1d1d0 outset 2px;
  background-color: #c0c0c0;
  font-weight: 600;
}

.swap-component button:hover{
  background-color: #d1d1d0;
}

.swap-component button:active{
  background-color: #bebebe;
  border: #bebebe inset 2px;
}


.streaming-group{
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;
}

.streaming-group>label{
  display: flex;
  flex-direction: row;
  margin-bottom: 3px;
}

.streaming-group>div{
  width: 100%;
}

.streaming-group>div>div{
  display: flex;
  flex-direction: row;
  margin-bottom: 3px;
  align-items: baseline;
}

.infobox>div>div{
  display:flex;
  flex-direction: column;
}

.infobox{

  margin: 5px 5px 2px 2px;
    border: #d1d1d0 inset 2px;
    background-color: #c0c0c0;
  text-align: left;
  font-size: 11px;
  font-weight: 600;
  padding: 5px;
  color: #000;
  display: flex;
  flex-direction: column;
}
.infobox>div{
  display: flex;
  flex-direction: row;
  margin-bottom: 1px;
  max-width: 100%;
  flex: 1 0 0;
  align-items: center;
}
.infobox>div>span{
  margin-right: 5px;
  flex-grow: 1;
  flex-basis: 0;
  text-align: left;
}
.infobox>div>span:last-child{
  margin-right: 0;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 2 0 0;
  overflow: hidden;
}

.tofrom>div>span:last-child {
  flex: 3 0 0;
}
.tofrom>div>span input{
  width: 100%;
  margin-left:0!important;
}

.tofrom>div>span:first-child{
  display: flex;
  align-items: center;
}

.inibox{
      flex-grow: 1;
        display: flex;
        flex-direction: column;
        margin:0;
        padding: 0 ;
        width: auto;
        min-height: 150px;
}

.inibox textarea{
  flex-grow: 1;
  width: 100%;
  height: 100%;
  resize: none;

}

.field-group select{
  margin-right: 5px;
}

.field-group input[type="range"]{
  margin:0;
  padding-left: 3px;
}

.swap-progress-container{
  border: 0!important;
}


.route-selection-group{
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;
}
.route-selection-group>label{
  display: flex;
  flex-direction: row;
  margin-bottom: 3px;
}

.swap-in-progress .route-selection-group{
  flex-direction: row;
}

.swap-in-progress .route-selection-group>label{
  margin-bottom: 0;
  display: block;
}
