/* Password.css */

.dialog-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.dialog-field {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
	font-size: 0.8em;
	font-weight: 600;
}


.dialog-content input, .dialog-content select {
  
	flex-grow: 1;
	margin-bottom: 10px;
  padding: 4px;
  border: 1px solid #ccc;
  margin-top: 10px;
	font-size: 1.2em;
  font-weight: 600;
  font-family: 'Courier New', Courier, monospace;
  align-self: center;
}

.dialog-content button {
  width: 100%;
  margin-top: 10px;
}

.dialog-content label {
  margin-bottom: 10px;
  margin-right: 5px;
  width: 100px;
  margin-top: 7px;
	  text-align: right;
	  align-self: center;
}