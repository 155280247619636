.nft-purchasing-component{
	/* fill all available space */
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
}

/* .dialog-box input{
	margin-top: 20px;
} */

.card-bar{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	/* height: 35px; */
	background-color: #fff;
	color: #000;
	font-family: 'Courier New', Courier, monospace;
	font-weight: 600;
	border-bottom: 1px solid #000;
	padding: 0;
	/* flex-flow: row-reverse; */
	flex-wrap: wrap;
}

.card-bar>div{
	display: flex;
	flex-direction: column;
	align-items: center;
	height:34px;
	justify-content: center;
}
.card-bar>div:first-of-type, .card-bar>div:last-of-type{

	flex: 1 1 300px;
	align-items: stretch;
}

.card-bar>div:nth-child(2){
	flex: 0 0 50px;
	background-color: #ccc;
	flex-direction: row;
}

@media screen and (max-width: 768px){
	.card-bar>div{
		min-height: 20px;
		height: fit-content	;
	}
	.card-bar>div:first-of-type, .card-bar>div:last-of-type{
		flex: 1 1 100%;
	}
	.card-bar>div:nth-child(2){
		top: 30px;
		position: absolute;
		right: 5px;	}
	
}


 .card-bar>div:last-of-type {
	min-width: 150px;
}

.card-bar>div>button{
	border: 2px outset #ccc;
	height: 100%;
	aspect-ratio: 1;
	background-color: #ccc;
	font-size: 22px;
	margin-left: 2px;
	margin-right: 2px;
}
.card-bar>div>button:hover{
	background-color: #d1d1d0;
}
.card-bar>div>button:active{
	background-color: #bebebe;
	border: 2px inset #ccc;
}
.card-bar>div>button:first-of-type{
	margin-left: 0;
}
.card-bar>div>button:last-of-type{
	margin-right: 0;
}

.card-bar-right>div{
	text-align: center;
}

.card-bar .collection-select{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-family: 'Courier New', Courier, monospace;
	font-weight: 600;
	font-size: 16px;
	border: none;
	/* remove down arrow */
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	text-align: center;
	/*fill available space*/
	flex: 1 1 100%;



	
}



.card-bar .collection-select:active{
	border: none;
}

.nft-number{
	max-height: 100%;
	text-align: center;
	vertical-align: middle;
}

.nft-purchasing-component .progress-bar-container{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	
	background-color: #fff;
	color: #000;
	font-family: 'Courier New', Courier, monospace;
	font-weight: 600;
	border: none!important;
	padding-top: 5px;
	margin: 0!important;
}

.nft-profile-details{
	max-width: 100%;
	box-sizing: border-box;
	height:350px;
}

.nft-details {
	position: relative;
	width: calc(100% - 5px);
	height: 100dvh;
	font-family: 'Courier New', Courier, monospace;
	font-weight: 600;
	background-color: #fff;
	display: flex;
	justify-content: flex-end;
	/* Aligns the selected NFTDetail on the bottom left */
	align-items: flex-end;
	padding: 20px;
	box-sizing: border-box;
	overflow-x: visible;
	overflow-y: hidden;
}

.nft-detail {
	position: absolute;
	/* Ensures overlapping effect */
	bottom: 20px;
	/* Align all profile NFTs to the bottom */
	left: 20px;
	/* Initial left position, adjusted dynamically */
	width: 90%;
	box-sizing: border-box;
	min-width: fit-content;
	border: 1px solid #000;
	font-family: 'Courier New', Courier, monospace;
	font-weight: 600;
	max-width: 750px;
	background-color: white;
	transition: transform 0.3s ease;
	/* Optional for smoother stacking */
	max-height:  calc(100% - 40px);
	overflow-y: auto;
}

.nft-detail h2 {
	border-bottom: #000 double 4px;
	width: 100%;
	margin-top: 0;
	padding-left: 5px;
	box-sizing: border-box;
	height: 30px;
	display: flex;
	overflow: hidden;
}

@media screen and (max-width: 768px){
	.nft-detail h2 {
		font-size: 1em;
		white-space: nowrap;
	}
	
}


.nft-detail-details {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
	flex-grow: 1;
}

.nft-detail img {
	max-width: calc(100% - 20px);
	max-height: 60dvw;
	width: 300px;
	margin: 10px;
	box-sizing: border-box;
}

.nft-detail-description {
	max-width: 325px;
}

.scroll-button {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 30px;
	height: 30px;
	font-size: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	z-index: 1000000;
}