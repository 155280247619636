.rdt_TableCol_Sortable {
  cursor: pointer;
  flex-direction: column;
}

.rdt_TableCol_Sortable>span{
	position: absolute;
	left:5px;
	top: 25px;
	display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 1;
}

.cell_inner{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        align-items: end;
              }

        .cell_inner.editor_number{
          align-items: end;
        }

        .cell_inner>*{
          max-width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
        }

.window-content:has(.exora){
  overflow: hidden;
}

.edit-column-buttons {
  display: flex;
  gap: 4px;
}

.edit-column-buttons button {
  padding: 2px 4px;
  cursor: pointer;
}

.edit-column-buttons button:hover {
  opacity: 0.8;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0.4; }
  100% { opacity: 1; }
}