.context-menu {
	position: fixed;
	background: white;
	border: 1px solid black;
	padding: 10px;
	z-index: 1000;
} 

.context-item {
	padding: 5px;
	cursor: pointer;
}

.context-item:hover {
	background: lightgray; 
}


.submenuitemicon {
	width: 16px;
	height: 16px;
	margin-right: 5px;
}