.calculator{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	background-color: #fff;
}


.calculator-display input{
	width: 100%;
	height: 50px;
	font-size: 2em;
	text-align: right;
	border: none;
	border-bottom: 1px solid #ccc;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	box-sizing: border-box;
	padding: 0 10px;
}



.calculator-buttons{
	width: 100%;
	height: 60%;
	min-height: 200px;
	max-height: 300px;
	box-sizing: border-box;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 1px;
	padding: 1px;
	background-color: #f4f4f4;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


.red-frame, .red-frame *{
	border: 1px solid red!important;
	background-color: #040000!important;
	color: #ff0000!important;

}

.red-frame #root>div, .red-frame p, .red-frame b, .red-frame img{
	background: none!important;
	border: none!important;
}