.send-component {
	box-sizing: border-box;
	background-color: #bebebe;
	box-sizing: border-box;
	background-color: #bebebe;
	max-width: 100vw;
	height: 100%;
	flex-grow: 1;
	overflow-y: auto;
}

.send-component>div {
	border: #d1d1d0 outset 2px;
	margin: 2px;
}


.send-toolbar {
	background-color: #c0c0c0;
}

.send-toolbar-icon {
	margin: auto;
	font-size: 2em;
}

.send-toolbar-button {
	margin: 2px 5px 2px 2px;
	border: #d1d1d0 outset 2px;
	background-color: #c0c0c0;
}

.send-toolbar-button:hover {
	background-color: #d1d1d0;
}

.send-toolbar-button:active {
	background-color: #bebebe;
	border: #bebebe inset 2px;
	
}


.send-progress-container {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 10px;
	flex-direction: column;
}

.send-explorer {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: 10px;
	flex-direction: column;
	font-size: 0.8em;
	font-weight: 600;
}

.send-error{
	color: red;
	font-size: 0.8em;
	font-weight: 600;
	width: calc(100% - 10px);
	margin: auto;
	text-align: center;
	box-sizing: border-box;
}