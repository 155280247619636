/* ConnectionApp.css */
.connection-app {
	position: relative;
}

.suggestions-box {
	position: absolute;
	top: 75px;
	/* Adjust according to the height of your textarea */
	width: 95%;
	max-height: 150px;
	/* Adjust max-height as needed */
	overflow-y: auto;
	border: 1px solid #ccc;
	background-color: #fff;
	z-index: 1000;
}



.suggestion-item {
	padding: 8px;
	cursor: pointer;
	color: black;
	/* Default text color */
}

.suggestion-item.highlighted {
	background-color: blue;
	color: white;
}

.suggestion-item.highlighted-checksum {
	background-color: yellow;
	color: black;
}

.hex-key-input-div {
	display: flex;
	max-width: 60%;

	flex-wrap: wrap;
}

.hex-key-input-div>div{
	max-width: 100%;
		white-space: wrap;
			word-wrap: break-word;
}

.hex-key-input-div button{
	margin-left:5px;
	height: 30px;
	width: 30px;
}