.nft-browsing-dialog {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 820px;
	max-width: 98vw;
	padding: 10px;
	background-color: #ccc;
}

@media screen and (max-width: 768px) {
	.nft-browsing-dialog {
		width: 100%;
		padding: 2px;
	}
	
}


.nft-dialog{
	background-color: #ccc;
}

.nft-dialog .dialog-buttons{
	justify-content: flex-end;
}

.nft-browsing-dialog .dialog-label{
	width: 100%;
	box-sizing: border-box;
	text-align: left;
}

.custom-select-container{
	padding: 4px;
	width: calc(100% - 8px);
}

.nft-scroll-container {
	display: flex;
	overflow-x: auto;
	padding: 10px;
	width: 100%;
	box-sizing: border-box;
	max-height: 70vh;
	/* Ensure it doesn't overflow the viewport */
}

.nft-grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
	gap: 10px;
	/* hgap: 10px; */

	width: calc(100% - 20px);
	grid-auto-flow: column;
	overflow-x: scroll;
	height: 100%;
	min-height: 70vh;
	/* try and get 4 rows, but if only 3 will fit then that's fine */
	grid-template-rows: auto auto auto auto auto;
	max-height: 75vh;
	overflow-y: hidden;
	background-color: #fff;
	border: 4px inset #ccc;
	

}




@media screen and (max-height: 1200px) {
	.nft-grid {
		grid-template-rows: auto auto auto auto;
	}	
}

.nft-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100px;
	height: 100px;
	/* height: 100%; */
	/* Increased height for title */
	/* border: 1px solid #ccc; */
	border: none;
	cursor: pointer;
	position: relative;
	padding: 2px;
	margin: 4px;
}

.nft-item>div{
	position: absolute;
	background-color: rgba(0, 0, 0, 0.5);
	color: white;
	top: 0;
	left: 5px;
}

.nft-image {
	width: 100%;
	height: 100px;
	/* Adjusted for the taller container */
	object-fit: cover;
}

.nft-item>div {
	margin-top: 5px;
	text-align: center;
	font-size: 14px;
}

.nft-item .nft-price{
		height: fit-content;
		position: absolute;
		left: 70px;
		width: fit-content;
}