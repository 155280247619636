.wallet-program{
	box-sizing: border-box;
	width: 100%;
	overflow: scroll;
}

.wallet-program>div{
	display: flex;
	flex-direction: column;
	min-height: 100%;
	box-sizing: border-box;
	overflow: visible;
}

.splitkeys{
	display: flex;
	flex-wrap: wrap;
	max-width: calc(100% - 20px);
	box-sizing: border-box;
}

.splitkeys li{
	max-width: calc(100% - 20px);
	box-sizing: border-box;
}

.splitkeys p{
	font-size: 0.8em;
	word-wrap: break-word;

}

.key-split{
	max-width: 100%;;
	box-sizing: border-box;
}